#help-center {
    padding-top: 7rem;

    .sidebar {
        position: fixed;
        z-index: 100;
        padding: 0;
        background-color: #ffffff;
        box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
        width: $sidebar-width;
        transition: all .2s ease;
        &.sidebar-full {
            width: $sidebar-width-full;
            .nav-title {
                display: inline !important;
            }
            .nav-group-title {
                display: inline !important;
            }
        }
        &:hover {
            width: $sidebar-width-full;
            .nav-title {
                display: inline !important;
            }
            .nav-group-title {
                display: inline !important;
            }
        }
        .nav {

            .nav-collapsible {
                color: #325AA9;
                text-decoration: none;
                font-size: 16px;
                &:hover {
                    color: #313131;
                }
            }
            .icon {
                // float: right;
                margin-right: 1rem;
                position: absolute;
                top: 4px;
                right: 0;
            }
            .nav-title {
                display: none;
            }
            .nav-item {
                margin-top: 2px;
            }
            .nav-link {
                font-weight: 500;
                color: #325AA9;
                &.active {
                    color: #313131;
                }
                &:hover {
                    color: #313131;
                }
            }
        }
    }

    .sidebar-sticky {
        position: -webkit-sticky;
        position: sticky;
        // top: 48px;
        /* Height of navbar */
        // height: calc(100vh - 48px);
        // padding-top: .5rem;
        overflow-x: hidden;
        overflow-y: auto;
        /* Scrollable contents if viewport is shorter than content. */
    }

    .help-content {
        margin-left: $sidebar-width-full;
        padding-left: 1rem;
    }

}