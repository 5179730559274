.button-blue {
    background-color: #325AA9;

    &:hover {
        background-color: #325AA9;
        box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px;
        transform: translateY(-2px);
        color: #fff;
    }

} 

.button-white {
    color: #325AA9;

    &:hover {
        background-color: #fff;
        box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px;
        transform: translateY(-2px);
        color: #325AA9;
    }
}