#section-1 {
    padding-top: 10rem;
    align-items: center;

    @media screen and (max-width: 768px) {
        padding-top: 5rem;
    }

    .left {
        align-self: center;

        .sub-title {
            color: #325AA9;
            font-weight: 600;
        }
    
        h1 {
            font-weight: 600;
            color: #313131;
        }

        .desc {
            text-align: justify;
            color: #3d3d3d;
        }

        @media screen and (max-width: 768px) {
            padding-top: 2rem;
        }
    }

    .right {
        background: url('/images/gradient-bg-header.png') no-repeat;
        background-size: contain;
        background-position: right;

        img {
            width: 100%;
            padding-top: 2rem;
        }
    }

}

#section-2 {
    padding-top: 5rem;
    text-align: center;

    .sub-title {
        color: #325AA9;
        font-weight: 600;
    }

    h2 {
        font-weight: 600;
        margin-bottom: 5rem;
        color: #313131;

        @media screen and (max-width: 768px) {
            margin-bottom: 0;
        }        
    }

    img {
        width: 20%;
        margin-bottom: 1rem;

        @media screen and (max-width: 768px) {
            padding-top: 3rem;
        }
    }

    h5 {
        font-weight: 600;
        margin-bottom: 1rem;
        color: #325AA9;
    }

    .desc {
        color: #3d3d3d;
        text-align: justify;
    }
}

#section-3 {
    padding-top: 5rem;
    
    img {
        width: 100%;
    }

    .left {
        align-self: center;

        img {
            width: 100%;
        }
    }

    .right {
        background: url('/images/gradient-bg.png') no-repeat;
        background-size: cover;
        display: flex;

        .content {
            align-self: center;
            color: #ffffff;
            padding: 2rem 2rem;

            .sub-title {
                font-weight: 600;
            }
    
            h4 {
                font-weight: 600;
            }
        }
    }
}

#section-4 {
    padding-top: 5rem;
    text-align: center;

    .sub-title {
        color: #325AA9;
    }

    h2 {
        font-weight: 600;
        color: #313131;
        margin-bottom: 5rem;
    }

    .period-content {
        text-align: left;

        .box {
            display: none;
        }

        s, strike {
            text-decoration:none;
            position:relative;
        }

        s::before, strike::before {
            top: 45%;
            background: red;
            opacity: 0.7;
            content: "";
            width: 110%;
            position: absolute;
            height: 0.1em;
            border-radius: 0.1em;
            left: -5%;
            white-space: nowrap;
            display: block;
            transform: rotate(-8deg);
        }

        s.straight::before, strike.straight::before {
            transform: rotate(0deg);
            left:-1%;
            width:102%;
        }

        .card {
            margin-top: 2rem;
            padding: 2rem;
            border: none;
            border-radius: unset;
            box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);

            .discount {
                position: absolute;
                right: 10px;
                top: 10px;

                .fas {
                    font-size: 5rem;
                    color: #e9a500;
                }

                .saving {
                    position: absolute;
                    right: 8px;
                    top: 13px;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 600;
                    text-align: center;
                }
            }

            .pricing {
                margin-bottom: 1rem;

                p {
                    margin-bottom: 0;
                }

                .normal-price {
                    font-size: 1.1rem;
                    font-weight: 400;
                }
    
                .special-price {
                    font-size: 2rem;
                    font-weight: 600;
                }
    
                .unit-period {
                    font-weight: 600;
                }
            }

            .desc {
                margin-bottom: 1rem;

                .fas {
                    color: #325AA9;
                }
                
                p {
                    margin-bottom: 0;
                }
            }
        }
    }

    // .row {

    //     .card {
    //         height: 90%;
    //         margin: 1rem 0;
    //         border: none;
    //         box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
    //     }

    //     .period {
    //         color: #325AA9;
    //         font-weight: 600;
    //         font-size: 20px;
    //     }

    //     .price {
    //         font-weight: 600;
    //         font-size: 40px;
    //     }

    //     .desc {
    //         // margin-bottom: 5rem;
    //         text-align: left;

    //         .fas {
    //             color: #325AA9;
    //         }
    //     }

    //     // .btn {
    //     //     position: absolute;
    //     //     bottom: 15px;
    //     //     right: 40px;
    //     //     width: 70%;
    //     // }

    // }
}

#hire-accountants {
    padding-top: 5rem;
    text-align: center;

    .sub-title {
        color: #325AA9;
    }

    h2 {
        font-weight: 600;
        color: #313131;
        margin-bottom: 5rem;
    }

    .left {
        img {
            width: 100%;
            padding-bottom: 1rem;
        }
    }

    .right {
        // align-self: center;

        p {
            text-align: justify;
            color: #3d3d3d;

            i {
                color: #325AA9;
            }
        }

        .btn {
            margin-top: 2rem;
        }
    }
}

#section-5 {
    padding-top: 5rem;
    text-align: center;

    .sub-title {
        color: #325AA9;
    }

    h2 {
        font-weight: 600;
        color: #313131;
    }

    .desc {
        color: #3d3d3d;
    }
}