// Fonts
@import url('https://fonts.googleapis.com/css?family=Poppins');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Vendor
@import "~@fortawesome/fontawesome-free/css/all.min.css";

@import 'button';
@import 'navbar';
@import 'footer';
@import 'home';
@import 'sidebar';