// Body
$body-bg: #ffffff;

// Typography
$font-family-sans-serif: 'Poppins', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

.container {
    max-width: 1200px !important;

    @media screen and (max-width: 768px) {
        width: 90% !important;
    }
}

$sidebar-width: 50px;
$sidebar-width-full: 200px;