.navbar {

    .navbar-brand {
        width: 20%;

        .logo {
            width: 130px;
            transition: all 0.3s;
        }
    }

    .navbar-collapse {
        flex-grow: unset;
    }

}