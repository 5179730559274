#footer {
    margin-top: 3rem;
    padding: 5rem 0;
    background: url('/images/background.png') no-repeat;
    background-size: cover;
    background-position: bottom;
    text-align: center;

    img {
        margin-bottom: 1rem;
        width: 170px;
    }
}